const loginvalidation=(customerlogin)=> {
    let errors={};
    if(!customerlogin.customer_mobile_number){
        errors.customer_mobile_number="Email or Mobile No is required.";
    }
    var customerotp=customerlogin.customer_otp;
    // if the user try to login with otp then password can be empty so if the otp length is 0 then he needs to enter else password is not manditory
    //alert(customerotp.length);
    if(customerotp.length==0){
    if(!customerlogin.customer_password){
        errors.customer_password="Password cannot be empty";
    }
}

   return errors;
}

export default loginvalidation