import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';
import '../../../scss/_custom.scss';
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CInput,
  CForm,
  CSelect,
  CRow,
  CLabel,
  CFormGroup,CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

const ProfileModal = (props) => {
  const [cookies, setCookies, removeCookie] = useCookies(['admin']);
  const [primary, setPrimary] = useState(false);
  let history=useHistory();
  const Logout=()=>{
    removeCookie("customer_guid");
    removeCookie("customer_name");
    removeCookie("customer_mobile_number");
    removeCookie("customer_email");
    removeCookie("customer_country_code");
    history.push('/');
    window.location.reload(true); 
    //alert(cookies.customer_guid,cookies.customer_name);
  }

  return (
    <>
      <CRow>
        <CCol>
          <CButton class="bgcyan white mr-1 mb-2 width100" style={{ padding: "5px", border: "unset" }}
            onClick={() => setPrimary(!primary)}
            //onMouseOver={() => setPrimary(!primary)}
          >
            {cookies.customer_name}
          </CButton>
          <CModal
            show={primary}
            onClose={() => setPrimary(!primary)}
            color="primary"
            size="sm"
          >
            <CModalBody>
            
              <CRow>
                <CCol xs="12" sm="12" md="12" lg="12">
                  <CFormGroup>
                    <Link
                      // to={{pathname:'/monthly-qualification/salt-spray-test-report-add'}}
                      style={{ color: "black", textDecoration: "none"}}
                      to={`/my-profile`}
                      className=" cyan width300">
                      <h6><i class="fa fa-user-circle" aria-hidden="true"></i> My Profile</h6>
                    </Link>
                  </CFormGroup>
                </CCol>

                <CCol xs="12" sm="12" md="12" lg="12">
                  <CFormGroup>
                    <Link
                      // to={{pathname:'/monthly-qualification/salt-spray-test-report-add'}}
                      style={{ color: "black", textDecoration: "none"}}
                      to={`/order-list`}
                      className=" cyan width300">
                      <h6><i class="fa fa-list" aria-hidden="true"></i> Orders</h6>
                    </Link>
                  </CFormGroup>
                </CCol>

                <CCol xs="12" sm="12" md="12" lg="12">
                  <CFormGroup>
                    <Link
                      // to={{pathname:'/monthly-qualification/salt-spray-test-report-add'}}

                      style={{ color: "black", textDecoration: "none"}}
                      to={`/cust-wish-list`}
                      className=" cyan width300">
                      <h6><i class="fa fa-heart" aria-hidden="true"></i> Wishlist</h6>
                    </Link>
                  </CFormGroup>
                </CCol>


                <CCol xs="12" sm="12" md="12" lg="12">
                  <CFormGroup>
                    <Link
                      // to={{pathname:'/monthly-qualification/salt-spray-test-report-add'}}
                      style={{ color: "black", textDecoration: "none"}}
                      to={``}
                      className=" cyan width300">
                      <h6><i class="fa fa-bell" aria-hidden="true"></i> Notification</h6>
                    </Link>
                  </CFormGroup>
                </CCol>

                <CCol xs="12" sm="12" md="12" lg="12">
                  <CFormGroup>
                    <Link
                      // to={{pathname:'/monthly-qualification/salt-spray-test-report-add'}}
                      style={{ color: "black", textDecoration: "none"}}
                      onClick={()=>Logout()} 
                      className=" cyan width300">
                      <h6><i class="fa fa-sign-out" aria-hidden="true"></i> Logout</h6>
                    </Link>
                  </CFormGroup>
                </CCol>

              </CRow>
            </CModalBody>
          </CModal>
        </CCol>
      </CRow>
    </>
  )
}
export default ProfileModal;