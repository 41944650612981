import 'font-awesome/css/font-awesome.min.css';
import '../../../scss/_custom.scss';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { CButton, CCol, CRow, CCard, CCardBody, CSelect, CLabel, CInput, CContainer } from '@coreui/react'
import 'bootstrap/dist/css/bootstrap.css';
import { useHistory } from "react-router-dom";
import CWHeader from './header';
import CWFooter from './footer';
import { useCookies } from 'react-cookie';


const RecentViewProduct = () => {
    const location = useLocation();
    const [cookies, setCookies, removeCookie] = useCookies(['customer']);

    let history = useHistory();
    const searchParams = new URLSearchParams(location.search);

    const [lastviewedproductlist, setLastViewedProductList] = useState([]);
    const LoadLastViewedProducts = async () => {
        var list = {};
        list["customer_guid"] = cookies.customer_guid;
        list["product_list_limit"] = "24";
        list["product_current_size"] = "0";

        await axios.post(process.env.REACT_APP_API + "FMGetCustomerLastViewedProducts", list).then(response => {
            console.log(response);
            if (response.data.is_success) {
                if (response.data.product_list != null) {
                    setLastViewedProductList(response.data.product_list);
                    setListCount(response.data.total_count);
                }
            }
        }).catch(
            error => {
                console.log(error);
            }
        )
    }

    useEffect(() => {
        LoadMoreLastViewedProducts();
    }, []);

    const [loadmorebtndisabled, setLoadMoreBtnDisabled] = useState(false);
    const [list_count, setListCount] = useState(0);
    const[currentsize, setCurrentSize]=useState(0);
    const [warnings, setWarnings] = useState({
      warning: "",
    });
    const LoadMoreLastViewedProducts = async () => {
        //e.preventDefault();
          
        if (currentsize != 0 && (currentsize >= list_count)) {
            setLoadMoreBtnDisabled(true);
            return
        }
        setWarnings({["warnings"]:""});
        setLoadMoreBtnDisabled(true);
        document.getElementById("img_gif_loading_btn2").style.display = "block";
    
      
        var list = {};
        list["customer_guid"] = cookies.customer_guid;
        list["product_list_limit"] =50 ;
        list["product_current_size"] = 0;

        await axios.post(process.env.REACT_APP_API + "FMGetCustomerLastViewedProducts", list).then(response => {
            console.log(response);
            if (response.data.is_success) {
                if (response.data.product_list != null) {
                    var prod_current_size=response.data.product_list.length;
                    console.log("Prod Current Size: "+prod_current_size);
                    setCurrentSize(prod_current_size);
                    var FinalList=lastviewedproductlist.concat(response.data.product_list);
                    setLastViewedProductList(FinalList);

                    var list_count=response.data.total_count;
                    setListCount(list_count);
                    //setLoadMoreBtnDisabled(true);
                }
                setWarnings({["warnings"]:""});
                setLoadMoreBtnDisabled(false);
                document.getElementById("img_gif_loading_btn2").style.display = "none";
            
            }
            else{
                setWarnings({["warnings"]:response.data.msg});
                setLoadMoreBtnDisabled(false);
                document.getElementById("img_gif_loading_btn2").style.display = "none";  
            }
        }).catch(
            error => {
                console.log(error);
                setLoadMoreBtnDisabled(false);
                document.getElementById("img_gif_loading_btn2").style.display = "none";
            }
        )
    }


    return (
        <>
            <CWHeader />
            <div className='p-2'>
                <CRow>
                    <CCol sm="12" lg="12" xl="12" md="12" xs="12">
                        <CCol sm="12" lg="12" xl="12" md="12" xs="12">
                            <CRow>
                                <CCol sm="12" lg="12" xl="12" md="12" xs="12" >
                                    <CCard>
                                        <CCardBody>
                                            <CCard className="m-2">
                                                <CCardBody>
                                                    <CRow>
                                                        <CCol sm="9" lg="10" xl="10" md="10" xs="9" >
                                                            {/* <h4 style={{fontSize:"20px"}}>{productlist.hpp_heading_name}</h4> */}
                                                            <h4 style={{ fontSize: "20px" }}>Recently Viewed Products</h4>                                                 
                                                        </CCol>
                                                        <CCol sm="3" lg="2" xl="2" md="2" xs="3"> 

                                  {/* <CButton onClick={() => LoadMoreLastViewedProducts()} className="btn bgblue white mr-1 width100" disabled={loadmorebtndisabled} style={{backgroundColor:"#861F41", color:"white"}}  >Load More</CButton> */}
                                  <div className="bgcyan"  style={{ borderRadius: "5px" }}>
            
            <CButton
     
              onClick={() => LoadMoreLastViewedProducts()}
              
              style={{ width: "100%", color: "white"}}
            //   style={{backgroundColor:"#861F41", color:"white"}}
              disabled={loadmorebtndisabled}>
                      Load More  </CButton>
            <img id="img_gif_loading_btn2" src={process.env.PUBLIC_URL+'/avatars/gif_loading.gif'} style={{width: "20px",marginTop:"-26px",float:"right",marginRight: "10px",display:"none"}}/>
           
          </div>
          <p style={{ color: "red" }}>{warnings.warning}</p>
                                                       
                                                        </CCol>
                                                    </CRow>
                                                    <hr/>
                                                    <CRow>
                                                        
                                                        {lastviewedproductlist.map((products, indexproduct) => (

                                                            <CCol sm="4" lg="2" xl="2" md="3" xs="4" className='mb-2 productimg' key={indexproduct}>
                                                                <Link
                                                                   //to={`/cust-prod-details?pguid=${products.product_guid}`}
                                                                     to={`/cust-prod-detail?pguid=${products.product_guid}`}

                                                                    style={{ color: "black", textDecoration: "none" }}
                                                                >
                                                                    <div >
                                                                        {/* <img className='productimg1' height="120px" width="150px" style={{marginLeft:"4px"}} src={`https://localhost:44323/Uploads/ProductImages/${products.product_cover_image}`} ></img> */}

                                                                        <img className='productimg1' height="120px" width="150px" style={{ marginLeft: "4px" }} src={process.env.REACT_APP_PHOTOPATH + `/ProductImages/${products.product_cover_image}`}></img>
                                                                        <h6 className='textalign'>{products.product_name}</h6>
                                                                        <h6 className='textalign'>₹{products.product_selling_price}</h6>
                                                                    </div>
                                                                </Link>
                                                            </CCol>
                                                        ))
                                                        }
                                                    </CRow>
                                                </CCardBody>
                                            </CCard>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                        </CCol>
                    </CCol>
                </CRow>
            </div>
            <CWFooter />
        </>
    )
}
export default RecentViewProduct;