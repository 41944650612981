import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import TextField from '@mui/material/TextField';
//import { TextField } from '@mui/material';
//import parse from 'html-react-parser';
import '../../../scss/_custom.scss';
import CIcon from '@coreui/icons-react'
import { toast } from 'react-toastify';
import { useCookies } from 'react-cookie';

import axios from "axios";
import {
  CButton, CCard, CCardBody, CCardHeader, CCol, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle, CInput, CForm, CSelect, CRow, CLabel, CFormGroup,

  CCardGroup,
  CContainer,
  CInputGroup,

  CLink,

  CInputGroupPrepend,
  CInputGroupText,
} from '@coreui/react'
import registervalidation from './registervalidation';
const RegisterModal = (props) => {

  const [primary, setPrimary] = useState(false);
  let history = useHistory();
  let d = new Date();
  d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000));
  const [cookies, setCookies, removeCookie] = useCookies(['seller']);
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({
    warning: ""
  });
  const [sendotpwarnings, setsendotpWarnings] = useState({
    sendotpwarning: ""
  });
  const [verifyotpwarnings, setverifyotpWarnings] = useState({
    verifyotpwarning: ""
  });
  const [disablebuttonsendotp, setDisableButtonsendotp] = useState(true);
  const [disablebuttonverifyotp, setDisableButtonverifyotp] = useState(true);
  const [disablebuttonreg, setDisableButtonreg] = useState(false);

  const [register, setregister] = useState({
    customer_country_code: "+91",
    customer_name,
    customer_mobile_number: "",
    customer_email: "",
    customer_password: "",
    customer_otp: "",
    Confirmpwd: ""

  });
  const { customer_country_code, customer_name, customer_email, customer_mobile_number, customer_password, customer_otp, Confirmpwd } = register;

  const OnInputChange = (e) => {
    console.log(e.target.value);
    setregister({ ...register, [e.target.name]: e.target.value });
    if(e.target.name==="customer_mobile_number"){
      setDisableButtonsendotp(false);
    }
    if(e.target.name==="customer_otp"){
      setDisableButtonverifyotp(false);
    }
    

  }
  const Sendotp = async () => {
    // await axios.post(process.env.REACT_APP_API + "CustomerSendOTP", register)
    //   .then(response => {
    //     console.log(response);
    //     if (response.data.is_success) {
    //       toast.success(response.data.msg);
    //       console.log(response);
    //     }
    //   }).catch(
    //     error => {
    //       console.log(error);
    //     }
    //   )

    // console.log(result);
    //"C:\Program Files\Google\Chrome\Application\chrome.exe" --args --disable-web-security --user-data-dir="C:\chrome_temp"
    setDisableButtonsendotp(true);
    // setDisableButtonverifyotp(true);
     setDisableButtonreg(true);
     document.getElementById("img_gif_loading_btnsotp").style.display = "block";
     //we cannot make this style block because it is already hiden once the response of send otp is success at that time we are showing that div so.it will throw error as cannot read properties of null
     //document.getElementById("img_gif_loading_btn_votp").style.display = "block";
     document.getElementById("img_gif_loading_btnregister").style.display = "block";
 
 
     await axios.post(process.env.REACT_APP_API + "CustomerSendOTP", register
    //  {
    //    "seller_country_code": seller_country_code,
    //    "seller_mobile_number": seller_mobile_number,
    //    "seller_email": seller_email
    //  }
     ).then(response => {
       console.log(response);
       if (response.data.is_success) {
         toast.success(response.data.msg);
         setsendotpWarnings({ ["sendotpwarning"]: "" });
 
        //  SendWhatsApp(seller_mobile_number, response.data.otp);
         setDisableButtonsendotp(false);
         //setDisableButtonverifyotp(false);
           setDisableButtonreg(false);
       
         document.getElementById("img_gif_loading_btnsotp").style.display = "none";
        // document.getElementById("img_gif_loading_btn_votp").style.display = "none";
         document.getElementById("img_gif_loading_btnregister").style.display = "none";
 
 
       }
       else {
         setsendotpWarnings({ ["sendotpwarning"]: response.data.msg });
         document.getElementById("img_gif_loading_btnsotp").style.display = "none";
         //document.getElementById("img_gif_loading_btn_votp").style.display = "none";
         
         document.getElementById("img_gif_loading_btnregister").style.display = "none";
 
         setDisableButtonsendotp(false);
        // setDisableButtonverifyotp(false);
           setDisableButtonreg(false);
                  //  sethideverifyotp(true);
 
       }
     }).catch(
       error => {
         console.log(error);
         alert(error.message);
         setDisableButtonsendotp(false);
         //setDisableButtonverifyotp(false);
         setDisableButtonreg(false);
         document.getElementById("img_gif_loading_btnsotp").style.display = "none";
         //document.getElementById("img_gif_loading_btn_votp").style.display = "none";
         document.getElementById("img_gif_loading_btnregister").style.display = "none";
 
        // sethideverifyotp(true);
 
       })
   }
 

  // const[verifyotp,setverifyotp]=useState({
  //   customer_country_code:"+91",
  //   customer_otp:"",
  //   customer_email:"",
  //   customer_mobile_number:""
  // })
  // const{customer_country_code,customer_otp,customer_mobile_number,customer_mobile_number}=verifyotp;
  // const OnInputChangeotp=(e)=>{
  //   console.log(e.target.value);
  //   setverifyotp({...verifyotp,[e.target.name]:e.target.value});
  // }
const[otpverified,setotpverified]=useState(false);
  const Verifyotp = async () => {
    // await axios.post(process.env.REACT_APP_API + "FMCustomerVerifyOTP", register)
    //   .then(response => {
    //     console.log(response);
    //     if (response.data.is_success) {
    //       toast.success(response.data.msg);
    //       console.log(response);
    //     }
    //   }).catch(
    //     error => {
    //       console.log(error);
    //     }
    //   )
      setDisableButtonverifyotp(true);

      document.getElementById("img_gif_loading_btn_votp").style.display = "block";
  
      await axios.post(process.env.REACT_APP_API + "FMCustomerVerifyOTP", register).then(response=>{
        if (response.data.is_success) {
          console.log(response);
          toast.success(response.data.msg);
          setverifyotpWarnings({ ["verifyotpwarning"]:response.data.msg});
          // setDisableVerifyField(true);
          setDisableButtonverifyotp(true);
          // setshowgreencolormsg(true);
          document.getElementById("img_gif_loading_btn_votp").style.display = "none";
          setotpverified(true);
  
        }
        else {
          document.getElementById("img_gif_loading_btn_votp").style.display = "none";
  
          setverifyotpWarnings({ ["verifyotpwarning"]: response.data.msg });
          setDisableButtonverifyotp(false);  
          // setDisableVerifyField(false);
          // setshowredcolormsg(true);
          setotpverified(false);
        
        }
  
      }).catch(error=>{
        console.log(error);
        alert(error.message);
        setDisableButtonverifyotp (false);
        //setDisableVerifyField(false);
        setotpverified(false);
        document.getElementById("img_gif_loading_btn_votp").style.display = "none";
  
      })
     
    }
  const Submit = async (e) => {
    if(otpverified===true){
    e.preventDefault();
    // await axios.post(process.env.REACT_APP_API + "FMCustomerRegister", register)
    //   .then(response => {
    //     console.log(response);
    //     if (response.data.is_success) {
    //       console.log(response);
    //       toast.success(response.data.msg);
    //       SendMailForRegistration(response.data.customer_details.customer_email, response.data.customer_details.customer_guid);
    //     }
    //     else {
    //       setWarnings({ ["warning"]: response.data.msg });

    //     }
    //   }).catch(
    //     error => {
    //       console.log(error);
    //     }
    //   )
    //console.log(result);
    setWarnings({ ["warning"]:""});
    setDisableButtonreg(true);
    document.getElementById("img_gif_loading_btnregister").style.display = "block";
    setErrors(registervalidation(register));
    var errorcount = Object.keys(registervalidation(register)).length;
    if (errorcount === 0) {
      // var result = await axios.post(process.env.REACT_APP_API + "BWSellerRegister", sendotp);
   if(customer_password===Confirmpwd){
    await axios.post(process.env.REACT_APP_API + "FMCustomerRegister", register).then(response=>{
      if(response.data.is_success){
        toast.success(response.data.msg);
        console.log(response);
        //comment1 SendMailForRegistration(response.data.customer_details.customer_email, response.data.customer_details.customer_guid);

        setWarnings({ ["warning"]:""});
        setDisableButtonreg(false);
        document.getElementById("img_gif_loading_btnregister").style.display = "none";
        // history.push('/sellerlogin');
        // window.location.reload(true);
        setPrimary(!primary);
      }else{

        setWarnings({ ["warning"]:response.data.msg});
        setDisableButtonreg(false);
        document.getElementById("img_gif_loading_btnregister").style.display = "none";
      }
     
    }).catch(error=>{
      setDisableButtonreg(false);
      document.getElementById("img_gif_loading_btnregister").style.display = "none";
    })
  }
  else{
    setWarnings({ ["warning"]:"Password did not match"});
    setDisableButtonreg(false);
      document.getElementById("img_gif_loading_btnregister").style.display = "none";
  }
    }
    //end of errorif
    else{
     
      setDisableButtonreg(false);
      document.getElementById("img_gif_loading_btnregister").style.display = "none";
    }
  }
  //otp verification before clicking on register btn
  else{
    setWarnings({ ["warning"]:"Otp Verification Required"});
 
  }
  }

  const [sendmail, setSendmail] = useState({
    mail_to_email_ids: "",
    mail_subject: "Registration",
    mail_body: "<p>Dear Sir/ Madam,</p><p>Thanks &amp; regards,</p><p>for E-commerce,</p><p>India</p><p>This is an Electronically generated mail. Please donot replay to the mail. For Further any clarification mail to abc123@gmail.com</p>",
    mail_attachment_html_content: "",
    mail_for: "",
    logged_in_user_guid: cookies.admin_guid
  });

  const SendMailForRegistration = async (customer_email, customer_guid) => {
    var htmldata = document.getElementById("id789").innerHTML;
    await axios.post(process.env.REACT_APP_API + "SendMail",
      //sendmail
      {
        "mail_to_email_ids": customer_email,
        "mail_subject": "Registration",
        "mail_body": "<p>Dear Sir/ Madam,<br/> Successfully Register To Pmap E-Commerce</p><p>Thanks &amp; regards,</p><p>for E-commerce,</p><p>India</p><p>This is an Electronically generated mail. Please donot replay to the mail. For Further any clarification mail to abc123@gmail.com</p>",
        //"mail_attachment_html_content": htmldata,
        "mail_for": "Testing",
        "logged_in_user_guid": customer_guid
      }).then(response => {
        console.log(response);
        if (response.data.is_success) {
          toast.success(response.data.msg);
        }
        else {
          toast.error(response.data.msg);
        }
      }).catch(error => {
        console.log(error);
      })
  }
  return (
    <CRow>
      <CCol>
        {/* <CButton style={{color:"#2874F0", fontWeight:"bold",backgroundColor:"white",borderRadius:"unset",padding:"2px",width:"100px",marginTop:"5px"}} onClick={() => setPrimary(!primary)}>
            Register

            </CButton> */}
        <CButton style={{ color: "#2980B9", fontWeight: "bold", border: "none", borderRadius: "unset", padding: "2px", width: "auto", marginTop: "5px" }} onClick={() => setPrimary(!primary)}>
          {/* Register */}
          New User? Create Account

        </CButton>

        <CModal
          show={primary}
          onClose={() => setPrimary(!primary)}
          color="primary"
          size="md"
          alignment="center"
        >
          <CModalHeader >
            <CModalTitle className="bgcyan white mt-2">Register</CModalTitle>
            <CButton className="btn bgcyan white" onClick={() => setPrimary(!primary)}>
              <h5 className='white'><i className="fa fa-close" aria-hidden="true"
              ></i></h5>
            </CButton>

          </CModalHeader>

          <CModalBody>

            <CContainer >
              <CRow className="justify-content-center">
                <CCol md="12" xs="12" sm="12" lg="12">
                  <CCardGroup>
                    <CCard>
                      <CCardBody>
                        <CForm >
                          <h1 className="textalign">Register</h1>

                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-user" />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput type="text" placeholder="Enter Your Name"
                              //autoComplete="new-password"
                              name="customer_name"
                              value={customer_name}
                              onChange={(e) => OnInputChange(e)}
                            />
                          </CInputGroup>
                          {errors.customer_name && <p style={{ color: "red" }}>{errors.customer_name}</p>}


                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CInputGroupPrepend>
                                <CInputGroupText>@</CInputGroupText>
                              </CInputGroupPrepend>
                            </CInputGroupPrepend>
                            <CInput type="Email" placeholder="Email"
                              //autoComplete="Email" 
                              name="customer_email"
                              value={customer_email}
                              onChange={(e) => OnInputChange(e)} />
                          </CInputGroup>
                          {errors.customer_email && <p style={{ color: "red" }}>{errors.customer_email}</p>}

                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CButton className="btn btn-light dropdown-toggle" type="button" data-toggle="dropdown">+91</CButton>
                           
                            </CInputGroupPrepend>
                            <CInput type="text" placeholder="Enter mobile number"
                              //autoComplete="Enter mobile number"
                              name="customer_mobile_number"
                              value={customer_mobile_number}
                              onChange={(e) => OnInputChange(e)}
                            />
                            <CInputGroupPrepend >
                              {/* <CButton className="width100" style={{ backgroundColor: "#2874F0", color: "white" }} onClick={() => Sendotp()}>Send OTP</CButton> */}
                              <div className="bgcyan" >
            <CButton onClick={()=>Sendotp()} style={{width:"100%",color:"white"}} disabled={disablebuttonsendotp}>Send OTP</CButton>
            <img id="img_gif_loading_btnsotp" src={process.env.PUBLIC_URL+'/avatars/gif_loading.gif'} style={{width: "20px",marginTop:"-26px",float:"right",marginRight: "10px",display:"none"}}/>
            </div>
                            </CInputGroupPrepend>
                            <p style={{color:"red"}}>{sendotpwarnings.sendotpwarning}</p>

                          </CInputGroup>
                          {errors.customer_mobile_number && <p style={{ color: "red" }}>{errors.customer_mobile_number}</p>}

                          <CInputGroup className="mb-3">
                            <CInput type="text" placeholder="Enter OTP"
                              //autoComplete="Enter mobile number"
                              name="customer_otp"
                              value={customer_otp}
                              onChange={(e) => OnInputChange(e)} />
                                {otpverified===false?
                            <CInputGroupPrepend >
                              {/* <CButton className="width100" style={{ backgroundColor: "#2874F0", color: "white" }} onClick={() => Verifyotp()}>Verify OTP</CButton> */}
                              <div className="bgcyan" 
                      // style={{borderRadius:"5px"}}
                      >
            <CButton onClick={()=>Verifyotp()} style={{width:"100%",color:"white"}} disabled={disablebuttonverifyotp}>Verify OTP</CButton>
            <img id="img_gif_loading_btn_votp" src={process.env.PUBLIC_URL+'/avatars/gif_loading.gif'} style={{width: "20px",marginTop:"-26px",float:"right",marginRight: "10px",display:"none"}}/>
            </div>
                           
                            </CInputGroupPrepend>
                            :null}
                            {/* <p style={{color:"red"}}>{verifyotpwarnings.verifyotpwarning}</p> */}
                  {otpverified===false?
               <p style={{color:"red"}}>{verifyotpwarnings.verifyotpwarning}</p>:
             <p style={{color:"green",marginBottom:"0px",paddingTop:"6px"}}>{verifyotpwarnings.verifyotpwarning}<i class="fa fa-check" aria-hidden="true"></i></p>
                  }

                          </CInputGroup>
                          {errors.customer_otp && <p style={{ color: "red" }}>{errors.customer_otp}</p>}


                          <CInputGroup className="mb-3">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-lock-locked" />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput type="password" placeholder="Password"
                              //autoComplete="new-password"
                              name="customer_password"
                              value={customer_password}
                              onChange={(e) => OnInputChange(e)}
                            />
                          </CInputGroup>
                          {errors.customer_password && <p style={{ color: "red" }}>{errors.customer_password}</p>}

                          <CInputGroup className="mb-4">
                            <CInputGroupPrepend>
                              <CInputGroupText>
                                <CIcon name="cil-lock-locked" />
                              </CInputGroupText>
                            </CInputGroupPrepend>
                            <CInput type="password" placeholder="Confirm password"
                              //autoComplete="new-password" 
                              name="Confirmpwd"
                              value={Confirmpwd}
                              onChange={(e) => OnInputChange(e)} />
                          </CInputGroup>
                          {errors.Confirmpwd && <p style={{ color: "red" }}>{errors.Confirmpwd}</p>}

                          <div className="mt-4 bgcyan" style={{borderRadius:"5px"}}>
            <CButton onClick={(e)=>Submit(e)} style={{width:"100%",color:"white"}} disabled={disablebuttonreg}> Register</CButton>
            <img id="img_gif_loading_btnregister" src={process.env.PUBLIC_URL+'/avatars/gif_loading.gif'} style={{width: "20px",marginTop:"-26px",float:"right",marginRight: "10px",display:"none"}}/>
            </div>
                          {/* <CButton className="width100" style={{ backgroundColor: "#2874F0", color: "white" }} onClick={() => Submit()} disabled={Confirmpwd.length < 8}>Register</CButton> */}
                          {warnings.warning && <p className="red">{warnings.warning}</p>}

                          <CLink
                            onClick={() => setPrimary(!primary)}
                          //to="/sellerlogin"
                          >Already Register? Login Here</CLink>

                        </CForm>
                      </CCardBody>

                    </CCard>
                  </CCardGroup>
                </CCol>
              </CRow>
            </CContainer>
            <div class="page" data-size="A4" id='id789'
              style={{ display: "none" }}
            >
              <div
                style={{ marginLeft: "0px", marginRight: "0px", display: "flex", flexWrap: "wrap" }}>
                <div class="column" style={{ width: "100%", overflowX: "auto" }}></div>
                <p> Dear Sir/Madam,</p>
                <p> You Are Successfully Register To Pmap E-commerce</p>
              </div>
            </div>
          </CModalBody>
        </CModal>
      </CCol>
    </CRow>

  )
}
export default RegisterModal;