import React, { useEffect, useState,useCallback } from 'react';
import {Link,useHistory} from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import TextField from '@mui/material/TextField';
//import { TextField } from '@mui/material';
//import parse from 'html-react-parser';
import '../../../scss/_custom.scss';
import CIcon from '@coreui/icons-react'
import { toast } from 'react-toastify';
import {useCookies} from 'react-cookie';
import loginvalidation from './loginvalidation'

import axios from "axios";
import {CButton,CCard,CCardBody,CCardHeader,CCol,CModal,CModalBody,CModalFooter,CModalHeader,CModalTitle,CInput,CForm,CSelect,CRow,CLabel,CFormGroup,
   
    CCardGroup,
    CContainer,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText,
    } from '@coreui/react'
import RegisterModal from './RegisterModal';
const LoginPage = (props) => {

    const [primary, setPrimary] = useState(false);
    let history=useHistory();
    let d=new Date();
   // d.setTime(d.getTime()+(1*24*60*60*1000)); expires in 1 day
    d.setTime(d.getTime()+(30*24*60*60*1000));//expires in one month

   const[cookies,setCookies,removeCookie]=useCookies(['customer']);
   const[errors,setErrors]=useState({});
   const[warningsotp,setWarningsotp]=useState({
     warningotp:""
   });
   const[warningvotp,setWarningvotp]=useState({
    warningvotp:""
  });
  const[warnings,setWarnings]=useState({
    warning:""
  });
  const [disablebutton, setDisableButton] = useState(false);
  const [disablebuttonotp, setDisableButtonotp] = useState(true);
  const [disablebuttonvotp, setDisableButtonvotp] = useState(false);
  //const [disablebuttonsendotp, setDisableButtonsendotp] = useState(true);

   const[customerlogin,setcustomerlogin]=useState({
     customer_country_code: "+91",
   customer_mobile_number: "",
   customer_email: "",
   customer_password: "",
   customer_otp:"",
   customer_otp1:"",
   customer_otp2:"",
   customer_otp3:"",
   customer_otp4:"",


   });
   const{customer_country_code,customer_mobile_number,customer_email,customer_password,customer_otp,customer_otp1,customer_otp2,customer_otp3,customer_otp4}=customerlogin;
   const OnInputChange=(e)=>{
     console.log(e.target.value);
   if(e.target.name==="customer_otp1"){
      var a;
      // a=e.target.value;
      // var b=a+customer_password;
      // alert(b);
      a=e.target.value+customer_otp2+customer_otp3+customer_otp4;
      //alert(a);
      setcustomerlogin({...customerlogin,["customer_otp"]:a,["customer_otp1"]:e.target.value});

   }
   else if(e.target.name==="customer_otp2"){
    var a;
    a=customer_otp1+e.target.value+customer_otp3+customer_otp4;
    console.log(customer_otp1+e.target.value+customer_otp3+customer_otp4);
    //alert(a);
    setcustomerlogin({...customerlogin,["customer_otp"]:a,["customer_otp2"]:e.target.value});

   }
   else if(e.target.name==="customer_otp3"){
    var a;
    //alert(customer_otp1);
    a=customer_otp1+customer_otp2+e.target.value+customer_otp4;
    //alert(a);
    setcustomerlogin({...customerlogin,["customer_otp"]:a,["customer_otp3"]:e.target.value});

  }
  else if(e.target.name==="customer_otp4"){
    var a;
    a=customer_otp1+customer_otp2+customer_otp3+e.target.value;
    //alert(a);
    setcustomerlogin({...customerlogin,["customer_otp"]:a,["customer_otp4"]:e.target.value});

  }
   else{
    setcustomerlogin({...customerlogin,[e.target.name]:e.target.value});
    if(e.target.name==="customer_mobile_number"){
      setDisableButtonotp(false);
}

   }
   }
   //moving cursor to the next field
   const MoveFocustoNextField=(e,fieldid)=>{
    if(e.target.value.length >= e.target.maxLength) {
    document.getElementById(fieldid).focus(); 
    }
   

   }
   const Login=async()=>{
    // e.preventDefault();
    setWarnings({ ["warning"]:""});
    setDisableButton(true);
    document.getElementById("img_gif_loading_btn").style.display = "block";
     setErrors(loginvalidation(customerlogin));
     var errorcount=Object.keys(loginvalidation(customerlogin)).length;
      if(errorcount===0){
          await axios.post(process.env.REACT_APP_API+"FMCustomerLogin",customerlogin).then(response=>{
            console.log(response);
            if(response.data.is_success){
              setCookies('customer_country_code',response.data.customer_details.customer_country_code,{path:'/',expires:d});
              setCookies('customer_email',response.data.customer_details.customer_email,{path:'/',expires:d});
              setCookies('customer_mobile_number',response.data.customer_details.customer_mobile_number,{path:'/',expires:d});
              setCookies('customer_name',response.data.customer_details.customer_name,{path:'/',expires:d});
              setCookies('customer_guid',response.data.customer_details.customer_guid,{path:'/',expires:d});
              setCookies('customer_email',response.data.customer_details.customer_email,{path:'/',expires:d});
 
              toast.success(response.data.msg);
              history.push("/");
              window.location.reload(true);
             setWarnings({["warning"]:""});

          }
          else{
            console.log(response.data.msg);
            setWarnings({["warning"]:response.data.msg});
             setDisableButton(false);
           document.getElementById("img_gif_loading_btn").style.display = "none";
        
          }
         
          }).catch(error=>{
            console.log(error);
            setDisableButton(false);
            document.getElementById("img_gif_loading_btn").style.display = "none";
          })
   }
   else{
    setDisableButton(false);
    document.getElementById("img_gif_loading_btn").style.display = "none";
   }
 }
 const[disableresendtimer,setdisableresendtimer]=useState(false);
 const[hideresendtimer,sethideresendtimer]=useState(false);

 const Sendotp=async()=>{
 
  sethideresendtimer(true);
  setWarningsotp({ ["warningotp"]:""});
    setDisableButtonotp(false);
    document.getElementById("img_gif_loading_btn1").style.display = "block";
  if(customer_mobile_number!=""){
  await axios.post(process.env.REACT_APP_API+"CustomerSendOTP",customerlogin)
   .then(response=>{
      console.log(response);   
      if(response.data.is_success)
      {
          toast.success(response.data.msg);
          console.log(response);
          setWarningsotp({ ["warningotp"]:""});
    setDisableButtonotp(true);
    document.getElementById("img_gif_loading_btn1").style.display = "none";
    setTimer(120);
    sethideresendtimer(true);


      }
      else{
        sethideresendtimer(false);

 setWarningsotp({ ["warningotp"]:response.data.msg});
    setDisableButtonotp(true);
    document.getElementById("img_gif_loading_btn1").style.display = "none";
      }
}).catch(
  error=>{
    sethideresendtimer(false);
    console.log(error);
    setDisableButtonotp(true);
    document.getElementById("img_gif_loading_btn1").style.display = "none";
  }
)

  }else{
    sethideresendtimer(false);
    setWarningsotp({["warningotp"]:"Enter Mobile No."});
    setDisableButtonotp(true);
    document.getElementById("img_gif_loading_btn1").style.display = "none";
  }
}

const Verifyotp=async()=>{
  if(customer_mobile_number!=""){
   // alert(customer_otp.length==4);
    if(customer_otp.length==4){
  await axios.post(process.env.REACT_APP_API+"FMCustomerVerifyOTP",customerlogin)
  .then(response=>{
    console.log(response);   
    if(response.data.is_success)
    {
        toast.success(response.data.msg);
        // history.push("/");
        // window.location.reload(true);
        //Login();
        setCookies('customer_country_code',response.data.customer_details.customer_country_code,{path:'/',expires:d});
        setCookies('customer_email',response.data.customer_details.customer_email,{path:'/',expires:d});
        setCookies('customer_mobile_number',response.data.customer_details.customer_mobile_number,{path:'/',expires:d});
        setCookies('customer_name',response.data.customer_details.customer_name,{path:'/',expires:d});
        setCookies('customer_guid',response.data.customer_details.customer_guid,{path:'/',expires:d});
        //setCookies('customer_email',response.data.customer_details.customer_email,{path:'/',expires:d});

        //toast.success(response.data.msg);
        history.push("/");
        window.location.reload(true);
       //setWarnings({["warning"]:""});

    }
    else{
      //toast.error(response.data.msg);
      setWarningvotp({["warningvotp"]:response.data.msg});

    }
}).catch(
error=>{
  console.log(error);
}
)
    }else{
      setWarningvotp({["warningvotp"]:"Enter OTP."});
 
    }
}else{
 
  setWarningvotp({["warningvotp"]:"Enter Mobile No."});
 
}
}
//const [timer, setTimer] = useState(60); 
const [timer, setTimer] = useState(0);    

const timeOutCallback = useCallback(() => setTimer(currTimer => currTimer - 1), []);

useEffect(() => {
  timer > 0 && setTimeout(timeOutCallback, 1000);
  //if else condition to make the resend btn enable when timer is 0
  //alert(timer);
  if(timer<=0){
    setdisableresendtimer(false);
    setTimer(0);
  }
  else{
    setdisableresendtimer(true);

  }
}, [timer, timeOutCallback]);

//console.log(timer);

const resetTimer = function () {
  //alert(1);
  if (!timer) {
    //setTimer(60);
    setTimer(120);

  }
};
return(
    <CRow>
        <CCol>
        {/* <p onPress={resetTimer}>Resend OTP ({timer})</p>
        <CButton onClick={resetTimer} className="mb-4 textalign">
                     <p className="textalign">Resend ({timer})</p>
                    </CButton>  */}
            <CButton style={{color:"#2980B9", fontWeight:"bold",backgroundColor:"white",
            //borderRadius:"unset",
            padding:"2px",width:"100px",height:"38px",
           // marginTop:"5px"
            }} onClick={() => setPrimary(!primary)}>
            Login
            </CButton>

            <CModal 
              show={primary} 
              onClose={() => setPrimary(!primary)}
              color="primary"
               size="lg"
               alignment="center"
            >
            <CModalHeader >
            <CModalTitle className="bgcyan white mt-2"> Log In</CModalTitle>
                <CButton className="btn bgcyan white" onClick={() => setPrimary(!primary)}>
                    <h5 className='white'><i className="fa fa-close" aria-hidden="true"
                    ></i></h5>
                </CButton>
                
            </CModalHeader>

            <CModalBody>
             
                <CContainer >
        <CRow className="justify-content-center">
          <CCol md="6" xs="12" sm="6" lg="6">
            <CCardGroup>
              <CCard>
                <CCardBody>
                  <CForm
                  //  onSubmit={(e)=>OnSubmit(e)}
                   >
                    <h1 className="textalign">Login</h1>
                   
                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="text" placeholder="Enter E-mail/Mobile No" 
                      //autoComplete="username"
                       name="customer_mobile_number"
                       values={customer_mobile_number}
                       onChange={(e)=>OnInputChange(e)}
                       
                       />
                       <CInputGroup>
                    {errors.customer_mobile_number&&<p style={{color:"red"}}>{errors.customer_mobile_number}</p>}
                    </CInputGroup>
                    </CInputGroup>
                    <CInputGroup>
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput type="password" 
                      // className="fa fa-eye-slash"
                       placeholder="Enter Password"
                       //autoComplete="current-password" 
                       name="customer_password"
                       values={customer_password}
                       onChange={(e)=>OnInputChange(e)}
                       
                       />
                    </CInputGroup>
                    {errors.customer_password&&<p style={{color:"red"}}>{errors.customer_password}</p>}

                        <CButton color="link" className="px-0" style={{float:"right"}}>Forgot password?</CButton>
                    <CInputGroup>
                        
                    {/* {errors.customer_password&&<p style={{color:"red"}}>{errors.customer_password}</p>} */}
                    </CInputGroup>
                    <div className="bgcyan" style={{ borderRadius: "5px" }}>
                      <CButton onClick={()=>Login()} style={{ width: "100%", color: "white" }} disabled={disablebutton}>Login</CButton>
                      <img id="img_gif_loading_btn" src={process.env.PUBLIC_URL + '/avatars/gif_loading.gif'} style={{ width: "20px", marginTop: "-26px", float: "right", marginRight: "10px", display: "none" }} />
                    </div>
                    {/* <CButton onClick={()=>Login()} className="px-4 width100" style={{backgroundColor:"#2874F0",color:"white"}}>Login</CButton> */}
                        <p style={{color:"red"}}>{warnings.warning}</p>
                        <p className='textalign'>OR</p>



                    {/* <CButton onClick={()=>Sendotp()}  className="px-4 width100" style={{backgroundColor:"#2874F0",color:"white"}}>Request OTP</CButton> */}
                    <div className="bgcyan" style={{ borderRadius: "5px" }}>
                      <CButton onClick={()=>Sendotp()} style={{ width: "100%", color: "white" }} disabled={disablebuttonotp}>Request OTP</CButton>
                      <img id="img_gif_loading_btn1" src={process.env.PUBLIC_URL + '/avatars/gif_loading.gif'} style={{ width: "20px", marginTop: "-26px", float: "right", marginRight: "10px", display: "none" }} />
                    </div>
                       
                        <p style={{color:"red"}}>{warningsotp.warningotp}</p>
                        <RegisterModal/>
                        {/* <Link to="/sellerregister" className="mb-4 textalign">
                     <p className="textalign">New User? Create Account</p>
                    </Link>    */}
                    <div>
                      <p>Please enter the OTP sent to</p>  
                      <p>
                        {customer_mobile_number}
                        <CButton color="link" className="px-0" style={{float:"right"}}>
                            Change</CButton>
                       </p>  

                    </div>
                    <div className='d-flex mb-2'>
                    <CInput type="text" 
                    //    autoComplete="current-password" 
                       name="customer_otp1"
                      id="customer_otp1"
                      onKeyUp={(e)=>MoveFocustoNextField(e,"customer_otp2")}
                       values={customer_otp1}
                       onChange={(e)=>OnInputChange(e)}
                       required
                       maxlength="1" 
                       autocomplete="off"
                       className='p-0 m-1'
                       style={{textAlign:"center"}}
                       />
                       <CInput type="text" 
                    //    autoComplete="current-password" 
                       name="customer_otp2"
                       id="customer_otp2"
                       onKeyUp={(e)=>MoveFocustoNextField(e,"customer_otp3")}

                       values={customer_otp2}
                       onChange={(e)=>OnInputChange(e)}
                       required
                       maxlength="1" 
                       autocomplete="off"
                       className='p-0 m-1'
                       style={{textAlign:"center"}}

                       />
                       <CInput type="text" 
                    //    autoComplete="current-password" 
                       name="customer_otp3"
                       id="customer_otp3"
                       onKeyUp={(e)=>MoveFocustoNextField(e,"customer_otp4")}

                       values={customer_otp3}
                       onChange={(e)=>OnInputChange(e)}
                       required
                       maxlength="1" 
                       autocomplete="off"
                       className='p-0 m-1'
                       style={{textAlign:"center"}}

                       />
                       <CInput type="text" 
                    //    autoComplete="current-password" 
                       name="customer_otp4"
                       id="customer_otp4"
                       values={customer_otp4}
                       onChange={(e)=>OnInputChange(e)}
                       required
                       maxlength="1" 
                       autocomplete="off"
                       className='p-0 m-1'
                       style={{textAlign:"center"}}

                       />
                        </div>
                    {/* <CButton onClick={()=>Verifyotp()} style={{backgroundColor:"#2874F0",color:"white"}} className="px-4 width100">Verify OTP</CButton> */}
                    <div className="bgcyan" style={{ borderRadius: "5px" }}>
                      <CButton onClick={()=>Verifyotp()} style={{ width: "100%", color: "white" }} disabled={disablebuttonvotp}>Verify OTP</CButton>
                      <img id="img_gif_loading_btn" src={process.env.PUBLIC_URL + '/avatars/gif_loading.gif'} style={{ width: "20px", marginTop: "-26px", float: "right", marginRight: "10px", display: "none" }} />
                    </div>
                        <p style={{color:"red"}}>{warningvotp.warningvotp}</p>
                     
                      {/* <Link to="/sellerregister" className="mb-4 textalign">
                     <p className="textalign">Resend</p>
                    </Link>   */}
                    {hideresendtimer!=false?
                   <CButton color="link" 
                   onClick={()=>Sendotp()}
                   className="textalign"
                   disabled={disableresendtimer}
                   style={{float:"right",textDecoration:"none"}}>
                  Resend
                   <span style={{cursor:"none"}}>({timer})</span></CButton>
            //         <p className="textalign" 
            //         // onClick={resetTimer} 
            //         onClick={()=>Sendotp()}
            //         style={{
            //           color:"#2874f0",
            //           fontWeight:"500",
                    
            //           cursor:"pointer"
                      
            //         }}
            //         disabled={disableresendtimer}
            //  >Resend 
            //  {/* ({timer}) */}
            //  <span style={{cursor:"none"}}>({timer})</span></p>
                     :null}
                   
                  </CForm>
                </CCardBody>
              </CCard>
              {/* <CCard className="text-white bg-primary py-5 d-md-down-none" style={{ width: '44%' }}>
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua.</p>
                    <Link to="/register">
                      <CButton color="primary" className="mt-3" active tabIndex={-1}>Register Now!</CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard> */}
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
                
            </CModalBody>
            </CModal>
        </CCol>
    </CRow>
)
}
export default LoginPage;