import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, HashRouter } from 'react-router-dom';
import './scss/style.scss';
import './scss/_custom.scss';
import { ToastContainer, toast, Zoom, Bounce } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
//IF WE USE HASHROUTER THEN WILL GET # IN THE URL EX.3000/#/DASHBORAD
import { useCookies } from 'react-cookie';
import RecentViewProduct from './views/CustomerPage/FrontPage/RecentViewedProdocts';


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)
//LAZY LOAD IMPROVES THE REACT BASED APPLICATION PERFORMANCE
//LAZY LOAD SPLITS THE BUNDLE.JS FILE BASED ON THE ROUTES HERE IT WILL CREATE A SEPARATE FILE FOR BOTH LOGIN AND REGISTER.
// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));
//SellerContainer


// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const FrontPage = React.lazy(() => import('./views/CustomerPage/FrontPage'))
const CWHeader = React.lazy(() => import('./views/CustomerPage/FrontPage/header'));
const CWFooter = React.lazy(() => import('./views/CustomerPage/FrontPage/footer'));
const Shipping = React.lazy(() => import('./views/CustomerPage/FrontPage/Shipping'));
const ReturnPolicy = React.lazy(() => import('./views/CustomerPage/FrontPage/ReturnPolicy'));
const PrivacyPolicy = React.lazy(() => import('./views/CustomerPage/FrontPage/PrivacyPolicy'));
const TermsandCondition = React.lazy(() => import('./views/CustomerPage/FrontPage/TermsandCondition'));
const CancelandReturnPolicy=React.lazy(()=>import('./views/CustomerPage/FrontPage/cancelandreturnpolicy'));
const FAQ = React.lazy(() => import('./views/CustomerPage/FrontPage/FAQ'));
const ContactUs = React.lazy(() => import('./views/CustomerPage/FrontPage/ContactUs'));
const AboutUs = React.lazy(() => import('./views/CustomerPage/FrontPage/AboutUs'));
const ProductList = React.lazy(() => import('./views/CustomerPage/FrontPage/ProductList'));
const CategoryOnclick = React.lazy(() => import('./views/CustomerPage/FrontPage/CategoryOnclick'));
const ProductDetails = React.lazy(() => import('./views/CustomerPage/FrontPage/ProductDetails'));
const CartList = React.lazy(() => import('./views/CustomerPage/FrontPage/Cart/CartList'));
const MyProfile=React.lazy(()=>import('./views/CustomerPage/FrontPage/MyProfile'));
const Customerwishlist=React.lazy(()=>import('./views/CustomerPage/FrontPage/Customerwishlist'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

//seller

const SellerLogin = React.lazy(() => import('./views/pages/SellerLogin/Login'));
const SellerRegister = React.lazy(() => import('./views/pages/SellerRegistration/Register'));

//seller layout and pages
const Welcomedashboard = React.lazy(() => import('./views/sellerViews/dashboard/welcomedashboard'));
const SellerHomeDashboard = React.lazy(() => import('./views/sellerViews/dashboard/SellerHomeDashboard'));
const TheLayoutseller = React.lazy(() => import('./containerSeller/TheSellerLayout'));
const SellerDashboard = React.lazy(() => import('./views/dashboard/SellerDashboard'));
const BusinessDetails = React.lazy(() => import('./views/SellerSettings/BusinessDetails'));
const AddNewProducts = React.lazy(() => import('./views/sellerViews/products/AddNewProducts'));
const AllProducts = React.lazy(() => import('./views/sellerViews/products/AllProducts'));
//listing layout and pages
const TheLayoutseller1 = React.lazy(() => import('./containerListing/TheListingLayout'));
const ListingDashboard = React.lazy(() => import('./views/dashboard/Listingdashboard'));



//payment routing
const PmapPayment = React.lazy(() => import('./views/PmapPayment/index'));
const PaymentResponse = React.lazy(() => import('./views/PmapPayment/PaymentResponse'));
const CODResponse=React.lazy(()=>import('./views/PmapPayment/CODResponse'));
//razorpaypayment
const RazorPayPayment = React.lazy(() => import('./views/RazorPayPayments/index'));
 const Razorpaypaymentresponse = React.lazy(() => import('./views/RazorPayPayments/Razorpaypaymentresponse'));
 const Razorcodresponse=React.lazy(()=>import('./views/RazorPayPayments/Razorcodresponse'));

const PhonepayForm=React.lazy(()=>import('./views/PhonePayPayments/PhonePayForm'));
const Success=React.lazy(()=>import('./views/PhonePayPayments/Success'));
const Failure=React.lazy(()=>import('./views/PhonePayPayments/Failure'));
const CustFrontPage = React.lazy(() => import('./views/CustomerPage/FrontPage/CustFrontPage'));
const CustProductDetails = React.lazy(() => import('./views/CustomerPage/FrontPage/CustProductDetails'));
const CustProductDetailsSame = React.lazy(() => import('./views/CustomerPage/FrontPage/CustProductDetailsSame'));
const RecentViewProductList = React.lazy(() => import('./views/CustomerPage/FrontPage/RecentViewedProdocts'));
const CustomerOrderList = React.lazy(() => import('./views/CustomerPage/FrontPage/CustomerOrderList'));
const CustomerCartList = React.lazy(() => import('./views/CustomerPage/FrontPage/Cart/CustomerCartList'));
const CustomerProductList = React.lazy(() => import('./views/CustomerPage/FrontPage/CustomerProductList'));
const CustomerOrderDetails = React.lazy(() => import('./views/CustomerPage/FrontPage/CustomerOrderDetails'));


const AppRoute = ({ component: Component, layout: Layout, ...rest }) => {
  return (
    <Route {...rest} render={props => (
      <Layout><Component {...props}></Component></Layout>
    )}>
    </Route>
  );
}


// class App extends Component {
const App = () => {

  // render() {
  const [cookies, setCookies, removeCookie] = useCookies(['user']);

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>

        <Switch>
          <AppRoute path='/seller/dashboard' name='SellerDashboard' layout={TheLayoutseller} component={SellerDashboard} />
          {/* <AppRoute path= '/seller/dashboard' name= 'SellerDashboard' layout={TheLayoutseller} component={SellerDashboard} /> */}
          <AppRoute exact path='/seller/settings' name='SellerSettings' layout={TheLayoutseller} component={BusinessDetails} />
          <AppRoute exact path='/seller/settings/business-details' name='Business Details' layout={TheLayoutseller} component={BusinessDetails} />
          <AppRoute exact path='/seller/products' name='Products' layout={TheLayoutseller} component={AddNewProducts} />
          <AppRoute exact path='/seller/products/add-new-products' name='Add New Products' layout={TheLayoutseller} component={AddNewProducts} />
          <AppRoute exact path='/seller/products/all-products' name='All Products' layout={TheLayoutseller} component={AllProducts} />

          {/* listing */}
          <AppRoute path='/listing/dashboard' name='ListingDashboard' layout={TheLayoutseller1} component={ListingDashboard} />
          <AppRoute exact path='/listing/products' name='All Products' layout={TheLayoutseller1} component={AllProducts} />
          <AppRoute exact path='/pmappayment' name='Pmap Payment' layout={PmapPayment} component={PmapPayment} />
          <AppRoute exact path='/payment-response' name='Payment Response' layout={PaymentResponse} component={PaymentResponse} />
          <AppRoute exact path='/cod-response' name='COD Response' layout={CODResponse} component={CODResponse} />
         {/* razorpaypayments */}
         <AppRoute exact path='/razorpaypayment' name='RazorPay Payment' layout={RazorPayPayment} component={RazorPayPayment} />
          <AppRoute exact path='/razor-payment-response' name='RazorPay Payment Response' layout={Razorpaypaymentresponse} component={Razorpaypaymentresponse} />
          <AppRoute exact path='/razor-cod-response' name='COD Response' layout={Razorcodresponse} component={Razorcodresponse} />
         
         
          <AppRoute exact path='/phone-pay-form' name='Form' layout={PhonepayForm} component={PhonepayForm} />
          <AppRoute exact path='/phonepe/success' name='Success' layout={Success} component={Success}/>
          <AppRoute exact path='/phonepe/failure' name='Failure' layout={Failure} component={Failure}/>

          <AppRoute exact path='/listing/products/all-products' name='All Products' layout={TheLayoutseller1} component={AllProducts} />

          <Route exact path="/seller/welcomedashboard" name="Welcomedashboard" render={props => <Welcomedashboard {...props} />} />
          <Route exact path="/seller/sellerhomedashboard" name="SellerHomeDashboard" render={props => <SellerHomeDashboard {...props} />} />

          {/* Login Page */}

          <Route exact path="/" name="Customer Page" render={props => <FrontPage {...props} />} />
          <Route exact path="/header" name="CWHeader" render={props => <CWHeader {...props} />} />
          <Route exact path="/footer" name="CWFooter" render={props => <CWFooter {...props} />} />
          <Route exact path="/shipping" name="Shipping" render={props => <Shipping {...props} />} />
          <Route exact path="/return-policy" name="ReturnPolicy" render={props => <ReturnPolicy {...props} />} />
          <Route exact path="/privacy-policy" name="PrivacyPolicy" render={props => <PrivacyPolicy {...props} />} />
          <Route exact path="/terms-and-condition" name="TermsandCondition" render={props => <TermsandCondition {...props} />} />
          <Route exact path="/cancel-and-return-policy" name="CancelandReturnPolicy" render={props => <CancelandReturnPolicy {...props} />} />

          
          <Route exact path="/faq" name="FAQ" render={props => <FAQ {...props} />} />
          <Route exact path="/contact-us" name="ContactUs" render={props => <ContactUs {...props} />} />
          <Route exact path="/about-us" name="AboutUs" render={props => <AboutUs {...props} />} />
          <Route exact path="/product-list" name="ProductList" render={props => <ProductList {...props} />} />
          <Route exact path="/category-on-click" name="CategoryOnclick" render={props => <CategoryOnclick {...props} />} />
          <Route exact path="/product-details" name="ProductDetails" render={props => <ProductDetails {...props} />} />
          <Route exact path="/cart-list" name="CartList" render={props => <CartList {...props} />} />
          <Route exact path="/sellerlogin" name="Login Page" render={props => <SellerLogin {...props} />} />
          <Route exact path="/sellerregister" name="Register Page" render={props => <SellerRegister {...props} />} />
          
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          

          <Route exact path="/cust-front-page" name="Cust Front Page" render={props => <CustFrontPage {...props} />} />
          <Route exact path="/cust-prod-details" name="Cust Front Page" render={props => <CustProductDetails {...props} />} />
          <Route exact path="/cust-prod-detail" name="Cust Front Page" render={props => <CustProductDetailsSame {...props} />} />
          <Route exact path="/recent-viewed-products" name="Cust Front Page" render={props => <RecentViewProductList {...props} />} />
          <Route exact path="/order-list" name="Customer Order List" render={props => <CustomerOrderList {...props} />} />
          <Route exact path="/cust-cart-list" name="Customer Cart List" render={props => <CustomerCartList {...props} />} />
          <Route exact path="/cust-prod-list" name="Customer Product List" render={props => <CustomerProductList {...props} />} />
          <Route exact path="/order-details" name="Customer Product Details" render={props => <CustomerOrderDetails {...props} />} />
          <Route exact path="/my-profile" name="My Profile" render={props => <MyProfile {...props} />} />
          <Route exact path="/cust-wish-list" name="Customer wishlist" render={props => <Customerwishlist {...props} />} />

             
        </Switch>
      </React.Suspense>
      <ToastContainer
        draggable={false}
        transition={Zoom}//zoom out
        autoClose={2000}//closing after 8sec

      />
    </HashRouter>

  );
}
export default App;
