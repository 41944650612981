import LoginPage from '../ModalPages/LoginPage';
import { CInput, CButton } from '@coreui/react'
import { useCookies } from 'react-cookie';
import React, { useEffect, useState } from 'react';
import { useHistory, Link,useLocation } from 'react-router-dom';
import axios from "axios";
import ProfileModal from './ProfileModal';
import Profilepage from './ProfilePage';
import {ReactSearchAutocomplete} from 'react-search-autocomplete';

const CWHeader = () => {
  const [searchtext, setSearchText] = useState({
    search_text: ""
  });

  const OnEnterKeyPress = (e) => {
    if (e.key === 'Enter') {
      console.log('enter press here! ');
      history.push(`/cust-prod-list?search=${searchtext.search_text}`);

    }
  }

  const OnChangeTextSearch = (e) => {
    setSearchText({ ...searchtext, ["search_text"]: e.target.value });
  }
  
  
   //search
   const items = [
    {
      id: 0,
      name: "Cobol",
    },
    {
      id: 1,
      name: "JavaScript",
    },
    {
      id: 2,
      name: "Basic",
    },
    {
      id: 3,
      name: "PHP",
    },
    {
      id: 4,
      name: "Java",
    },
  ];

 

  
  const handleOnSearch = (string, results) => {
   // const handleOnSearch = (e) => {
    // console.warn("e");
    // console.warn(e);

    //console.warn(e.key);//Uncaught TypeError: Cannot read properties of undefined (reading 'key')
  //   if(e.keyCode === 13){

  //     alert("Enter was pressed was presses");
  // }
    console.log("onchange");
    console.log(string,results);
    setSearchText({ ...searchtext, ["search_text"]:string});

    // if (e.key === 'Enter') {
    //   console.log('enter press here! ');
    //  // history.push(`/cust-prod-list?search=${searchtext.search_text}`);

    // }
    // document.getElementById("myForm").addEventListener("keypress",function(e) { 
    //   console.log(e.target.tagName,e.which === 13) 
    // })

  };

  const handleOnHover = (result) => {
    console.log(result);
  };

  const handleOnSelect = (item) => {
    console.log("onselectof option");
    console.log(item);
    console.log(item.search_text);
   // history.push(`/cust-prod-list?search=${item.search_text}`);
    // history.push({
    //   pathname: '/cust-prod-list',
    //   search: `?=${item.search_text}`,
    //   state:{
    //     searchtext:item.search_text
    //   } 
    // });
    

//const myQueryParamValue = "You&Me";
    //const ajaxUrl = "www.example.com/api?searchText="+encodeURIComponent(myQueryParamValue)
    const myQueryParamValue=item.search_text
     //1history.push("/cust-prod-list?search="+encodeURI(myQueryParamValue));//output http://localhost:1003/#/cust-prod-list?search=H&O%20Coconut%20Oil
    // 2 history.push("/cust-prod-list?search="+encodeURIComponent(myQueryParamValue));//http://localhost:1003/#/cust-prod-list?search=H&O%20Coconut%20Oil
    history.push(`/cust-prod-list?search=${encodeURIComponent(item.search_text)}`);//http://localhost:1003/#/cust-prod-list?search=H%26O%20Coconut%20Oil
     //1var searchtext = searchParams.get("search");//in custprod list page if we access search then output is H
     //2var searchtext = searchParams.get("search");//in custprod list page if we access search then output is H
     //3 var searchtext = searchParams.get("search");//in custprod list page if we access search then output is H&T Coconut Oil

    // let history=useHistory();
    // const{rid}=useParams();
    // const{bid}=useParams();
    // const{cid}=useParams();
    // const{rcn}=useParams();
    // if(props.location.ruid!=null){
    // var ruid=props.location.ruid.ruid;
    // console.log(ruid);
    // }
    // else{
    //   var ruid="";
    // }


  };

  const handleOnFocus = () => {
    console.log("Focused");
  };

  const handleOnClear = () => {
    console.log("Cleared");
  };
  const OnEnterKeyPress2 = (e) => {
    alert(e);
    console.log("onenterkeypress");
  };
  const handleKeyDown = (event) => {
    alert(event);
    if (event.key === 'Enter') {
      console.log('do validate')
    }
  }
  const [searchproductlist, SetSearchProductList] = useState([]);
  // useEffect(() => {
  //   if(product_search_text){
  //   const delayDebounceFn = setTimeout(() => {
  //     console.log(product_search_text);
  //     FMSearchProducts();
  //     // Send Axios request here
  //   }, 1000)
    
  //   return () => clearTimeout(delayDebounceFn)
  //   }
  //   }, [product_search_text])
  useEffect(() => {
    console.warn("useeffectofsearch");
    if(searchtext.search_text!=""){
    FMSearchProducts();
    }
  },[searchtext.search_text]);
  const FMSearchProducts = async () => {
    var list = {};
   
      list["search_text"] = searchtext.search_text;
     // list["search_text"] = "agar";

      list["list_limit"] = 10;
      list["current_size"] =0;

    
    // await axios.post(process.env.REACT_APP_API+"CustomerCartAddItem",list).then(response=>{
    // await axios.post("http://ictecapi.trackzon.in/api/FMCustomerCartItemCount",list).then(response=>{
    await axios.post(process.env.REACT_APP_API + "FMSearchProducts", list).then(response => {

      console.log(response);
      if (response.data.is_success) {
             if(response.data.list!=null){
              SetSearchProductList(response.data.list);
              console.warn("FMSearchProducts");
      
              console.warn(response.data.list);
             }
       

      }
    }).catch(
      error => {
        console.log(error);
      }
    )
  }

  let history = useHistory();

  const [cookies, setCookies, removeCookie] = useCookies(['admin']);

 
  useEffect(() => {
    Cartitemcount();
   // FMSearchProducts();
  }, []);
  // useEffect(() => { for useffect it is giving error as Error: Invalid hook call. Hooks can only be called inside of the body of a function component. This could happen for one of the following reasons:
// 1. You might have mismatching versions of React and the renderer (such as React DOM)
// 2. You might be breaking the Rules of Hooks
// 3. You might have more than one copy of React in the same app


  if (searchtext.search_text==="") {
  //url.includes('?');
  const location = useLocation();
//alert(location);
console.warn(location);
// output 
// hash: ""
// pathname: "/"
// search: ""
// state: undefined
  const searchParams = new URLSearchParams(location.search);
  // history.push({
  //   pathname: '/cust-prod-list',
  //   search: `?=${item.search_text}` 
  //   state:{
  //     searchtext:item.search_text
  //   } 
  // });
  //  alert(searchParams);
  //  alert(searchParams.get("search"));
  //  if(location.state!=null){

   
  //  const statesearchvalue=new URLSearchParams(location.state);
  //  alert("statesearchvalue");
  //  alert(statesearchvalue);
  // alert("statesearchvalue.searchtext");

   //alert(statesearchvalue.searchtext);//output undefined
  //  const statesearchvalue=new URLSearchParams(location.state.searchtext);
  // alert("statesearchvalue");
  // alert(statesearchvalue);

  //  }

 

  //  alert(new URLSearchParams(location.state));
  //  if(location.state!=null){
  //   alert(location.state.searchtext);

  //  }


  if(searchParams!=""){
    //this is not working properly because in the search if we have the value h&t oil the output is only h so we need to use state
  var searchtextlink = searchParams.get("search");
  console.log("searchtextlink" + searchtextlink);
  setSearchText({ ...searchtext, ["search_text"]: searchParams});
//above is the working commented code
  //searchtext.search_text=searchtextlink; this will not allow edit in search
  //Too many re-renders. React limits the number of renders to prevent an infinite loop.
  //setSearchText({ ...searchtext, ["search_text"]: searchtextlink});

  // setSearchText({["search_text"]: searchtextlink});
  //alert("if")
  // if(location.state!=null){
  //    //alert(location.state.searchtext);
  //   setSearchText({ ...searchtext, ["search_text"]: location.state.searchtext});

  //   }


    
   }
  }
// },[searchtext.search_text]);

  const [cartitemcount, SetCartItemCount] = useState(0);

  const Cartitemcount = async () => {
    var list = {};
    if (cookies.customer_guid != null) {
      list["cart_customer_guid"] = cookies.customer_guid;

    }
    // await axios.post(process.env.REACT_APP_API+"CustomerCartAddItem",list).then(response=>{
    // await axios.post("http://ictecapi.trackzon.in/api/FMCustomerCartItemCount",list).then(response=>{
    await axios.post(process.env.REACT_APP_API + "FMCustomerCartItemCount", list).then(response => {

      console.log(response);
      if (response.data.is_success) {

        SetCartItemCount(response.data.total_count);

      }
    }).catch(
      error => {
        console.log(error);
      }
    )
  }

  const Logout = () => {
    removeCookie("customer_guid");
    removeCookie("customer_name");
    removeCookie("customer_mobile_number");
    removeCookie("customer_email");
    removeCookie("customer_country_code");
    history.push('/');
    window.location.reload(true);
  }

  return (
    <>
      <header>
        <div class="py-3">
          <div class="container mt-4">
            {/* Some top header info for demo */}
          </div>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark bgcyan fixed-top">
          <div class="container">
            {/* <a class="navbar-brand p-1" href="#">PMAP Ecommerce</a> */}
            <a class="navbar-brand p-1 mr-0" href="#"> <img
              //src={`https://localhost:44323/Uploads/CompanyImages/${cookies.company_logo}`}
              src={process.env.REACT_APP_PHOTOPATH + `CompanyImages/${cookies.company_logo}`}
              width="100px" height="35px" />
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav">
              <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="main_nav">
              {/* ms-auto is bootstrap class that will push the nav items towards right */}
              <ul class="navbar-nav me-auto">
                <li class="nav-item ml-3">
                  <div className="inner-addon right-addon mqsearchbox" style={{width:"600px"}}>
                    {/* <i className="fa fa-search"></i> */}
                    {/* <CInput type="text"
                      placeholder="Search by Product name, brand and more"
                      name="search_text"
                      value={searchtext.search_text}
                      style={{ 
                        //borderRadius: "unset", 
                      width: "600px" }}
                      onKeyPress={(e) => OnEnterKeyPress(e)}
                      onChange={(e) => OnChangeTextSearch(e)}
                    ></CInput> */}
                    {/* <div style={{display:"none"}}> */}
                      <ReactSearchAutocomplete
                     // id="myForm"
                    //  value={searchtext.search_text} 
                    //  onKeyPress={(e) => OnEnterKeyPress(e)}
                    //  onChange={(e) => OnChangeTextSearch(e)}
            // items={items}
            items={searchproductlist}
            fuseOptions={{ keys: ["search_id", "search_text"] }}
            resultStringKeyName="search_text"
            //inputSearchString,
            // By changing this prop, you can manually set the search string.
            onSearch={handleOnSearch}
            //onSearch={(e)=>handleOnSearch(e,string, results)}
           // onSearch={(e)=>handleOnSearch(e)}//not working console it is undefined for e.string and e.result
            //OnEnterKeyPress={OnEnterKeyPress2}
            onKeyPress={(e)=>OnEnterKeyPress2(e)}
            onKeyDown={handleKeyDown}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            styling={{ zIndex: 4 }} // To display it on top of the search box below
            autoFocus
            placeholder='Search by Product name, brand and more'
            
          />
          {/* </div> */}
                  </div>
                </li>
                {/* <li>
                <div style={{ marginBottom: 20 }}>Try to type "JavaScript"</div>
          <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            styling={{ zIndex: 4 }} // To display it on top of the search box below
            autoFocus
            placeholder='Search by Product name, brand and more'
            
          />
                </li> */}

                {cookies.customer_guid == null ?
                  <li class="nav-item ml-3 pt-1"><LoginPage />
                  </li>
                  :
                  <li class="nav-item ml-3">
                    
                    {/* <ProfileModal/> */}
                    <Link
                      // to={{pathname:'/monthly-qualification/salt-spray-test-report-add'}}
                      style={{ color: "white", textDecoration: "none"}}
                      to={`/my-profile`}
                      className=" cyan width300">
                         {/* {cookies.customer_name} */}
                      <h6 style={{paddingTop:"16px"}}>
                        {cookies.customer_name}</h6>
                    </Link>
                  
                  </li>
                }
                <li class="nav-item ml-3" style={{fontSize:"22px"}}>

                  <Link to="/cart-list" >
                    <div style={{paddingTop:"7px"}}>
                      <i className="fa fa-shopping-cart white">
                        {cartitemcount!=0?
                        <sup>
                          <span class="badge badge-danger" style={{
                            border: "1px solid #fff",
                            marginLeft: "-8px", borderRadius: "50%"
                          }}>{cartitemcount}
                          </span>
                        </sup>
                        :null}
                        <span className="white" style={{ fontWeight: "500",
                        fontFamily:"sans-serif",
                        marginLeft:"10px" 
                        }}>Cart</span>
                      </i>
                    </div>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
}
export default CWHeader;
