const registervalidation=(register)=> {
    let errors={};
    if(!register.customer_name){
        errors.customer_name="Name is Required.";
    }
    if(!register.customer_email){
        errors.customer_email="Email is Required.";
    }
    if(!register.customer_mobile_number){
        errors.customer_mobile_number="Mobile No is Required.";
    }
    if(!register.customer_otp){
        errors.customer_otp="Otp is Required.";
    }
   
    var password=register.customer_password;
    if(!register.customer_password){
        errors.customer_password="Password is Required.";
    }
        else if(password.indexOf(' ')>=0){
            errors.customer_password="password cannot contain spaces";    

        }
        else if(password.length<8){
            errors.customer_password="Password Length should be more than 8 Characters";    

        }
        var conpassword=register.Confirmpwd;
    if(!register.Confirmpwd){
        errors.Confirmpwd="Conform Password is Required.";
    }
    else if(conpassword.indexOf(' ')>=0){
        errors.Confirmpwd="password cannot contain spaces";    

    }
    else if(conpassword.length<8){
        errors.Confirmpwd="Password Length should be more than 8 Characters";    

    }
    
   
   return errors;
}

export default registervalidation