import {CRow,CCol} from '@coreui/react'
import {useCookies} from 'react-cookie';
import {Link} from 'react-router-dom';

const CWFooter=()=>{
    const[cookies,setCookies,removeCookie]=useCookies(['admin']);

    return(
        <>
<CRow className='bgcyan white m-0 mt-3 pt-5 pl-5 pr-5 pb-5 width100'>
<CCol sm="6" lg="3" xl="3" md="3"  xs="6" className="mt-2">
   <p>ABOUT</p>
   {/* <p className='fsizefweight'>Contact Us</p> */}
   {/* <p className='fsizefweight'>About Us</p> */}

     <Link
    to="/contact-us"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
        Contact Us</Link>
        <br/>
        <Link
    to="/about-us"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
        About Us</Link>

    </CCol>
   
       
    <CCol sm="6" lg="3" xl="3" md="3"  xs="6" className="mt-2">
    <p>HELP</p>
    {/* <Link
    to="/shipping"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
       Shipping</Link> 
       <br/> */}
        <Link
    to="/faq"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
       FAQ</Link> 
       <br/>

        {/* <Link
    to="/return-policy"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
         Return Policy</Link> 
         <br/> */}

          <Link
    to="/privacy-policy"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
       Privacy Policy</Link>
       <br/>
  <Link
    to="/terms-and-condition"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
       {/* Terms of Use */}
       Terms and Conditions
       </Link>
       <br/>
       <Link
    to="/cancel-and-return-policy"
    className='fsizefweight white'
    style={{lineHeight:1,textDecoration:"none"}}
        >
       {/* Cancellation/Refund Policy */}
       Cancel and Return Policy
       </Link>
   {/* <p className='fsizefweight'>Shipping</p>
   <p className='fsizefweight'>FAQ</p>
   <p className='fsizefweight'> Return Policy</p>
   <p className='fsizefweight'>Privacy Policy</p>
   <p className='fsizefweight'>Terms of Use</p>
 */}

    </CCol>
    <CCol sm="6" lg="3" xl="3" md="3"  xs="6" className="mt-2">

    <p>SOCIAL</p>
    {/* //if we use link then it will redirect as localhost:3000/https:faceboom */}
    {/* <Link
    to={cookies.company_facebook_link}
    className='fsizefweight'
        >
        Facebook</Link> */}
        {cookies.company_facebook_link!=null?
 <a class="fsizefweight white" href={cookies.company_facebook_link} style={{lineHeight:1,textDecoration:"none"}}> 
 Facebook
</a>
:null}
<br/>
{cookies.company_youtube_link!=null?
<a  class="fsizefweight white" href={cookies.company_youtube_link} style={{lineHeight:1,textDecoration:"none"}}> 
Youtube
</a>
:null}

<br/>
{cookies.company_twitter_link!=null?

<a class="fsizefweight white" href={cookies.company_twitter_link} style={{lineHeight:1,textDecoration:"none"}}> 
Twitter
</a>
:null}

<br/>
{cookies.company_instagram_link!=null?

<a class="fsizefweight white" href={cookies.company_instagram_link} style={{lineHeight:1,textDecoration:"none"}}> 
Instagram 
</a>
:null}

<br/>
{cookies.company_linkedin_link!=null?

<a class="fsizefweight white" href={cookies.company_linkedin_link} style={{lineHeight:1,textDecoration:"none"}}> 
 Linkedin 
</a>
:null}

<br/>
{cookies.company_whats_app_link!=null?

<a class="fsizefweight white mb-0 " href={cookies.company_whats_app_link} style={{lineHeight:1,textDecoration:"none"}}> 
 Whatsapp
</a>
:null}

   {/* <p className='fsizefweight'>Facebook</p>
   <p className='fsizefweight'>Youtube</p>
   <p className='fsizefweight'>Twitter</p>
   <p className='fsizefweight'>Instagram</p> */}
    </CCol>
    {/* <CCol className="mt-2" sm="1" lg="1" xl="1" md="1"  xs="1" style={{borderRight:"1px solid white",height:"200px"}}>
</CCol>
<CCol className="mt-2" sm="1" lg="1" xl="1" md="1"  xs="1">
</CCol> */}
    <CCol sm="6" lg="3" xl="3" md="3"  xs="6" className='mt-2 mqpl' style={{borderLeft:"1px solid white",height:"auto"}}>

    <p>ADDRESS</p>
    <p className='fsizefweight'>{cookies.company_address_line_1}</p>
   <p className='fsizefweight'>{cookies.company_address_line_2}</p>
   <p className='fsizefweight'>{cookies.company_address_line_3}</p>
   {/* <p className='fsizefweight'>CIN</p> */}
   <p className='fsizefweight'>{cookies.company_business_number}</p>
   {/* <p className='fsizefweight'>#3022 Aj Agamma</p>
   <p className='fsizefweight'>Mysore</p>
   <p className='fsizefweight'>Karnataka</p>
   <p className='fsizefweight'>CIN</p>
   <p className='fsizefweight'>Phone</p> */}
    </CCol>
</CRow>


<CRow className='bgcyan white m-0 width100 pt-1 pl-1 pr-1' style={{borderTop:"1px solid white",width:"100%"}}>
<CCol sm="7" lg="4" xl="4" md="4"  xs="12">

   <p className='fsizefweight14'><i className="fa fa-briefcase"></i> Designed by Pmap Technotech Pvt Ltd</p>


    </CCol>
    <CCol sm="5" lg="4" xl="4" md="4"  xs="12">
        
   
    <p className='fsizefweight14'><i className="fa fa-envelope-o"></i> {cookies.company_email}</p>
    {/* <p className='fsizefweight14'><i className="fa fa-envelope-o"></i> info@pmaptechnotech.com</p> */}



    </CCol>
    <CCol sm="12" lg="4" xl="4" md="4"  xs="12">
    <p className='fsizefweight14'><i className="fa fa-mobile"></i> {cookies.company_phone}</p>

    </CCol>
   
   
</CRow>
</>
    )
}
export default CWFooter;
